import React from 'react';
import Home from './comp/pages/Home';
import Tech from './comp/pages/Tech';
import Cont from './comp/pages/Cont';
import AllowCookies from './comp/support/AllowCookies';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Link to="/"><Navbar.Brand className="colorBlue">SkySoftp</Navbar.Brand></Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/tech">Technology</Nav.Link>
                <Nav.Link as={Link} to="/cont">Contact</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/login">Login</Nav.Link>        
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/tech">
            <Tech />
          </Route>
          <Route path="/cont">
            <Cont />
          </Route>
        </Switch>
        
        <AllowCookies />
        <hr />               
        <div className="text-center">©SkySoftp® 2020</div>

      </div>
    </Router>
  );
}

export default App;
