import React from 'react';
import SendData from '../support/SendData';
import { Col, Row, Container, Jumbotron, Form, InputGroup, ButtonGroup, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { GoMail } from "react-icons/go";
import { HiOutlinePhoneIncoming } from "react-icons/hi";
import { BsArrowCounterclockwise, BsPerson } from 'react-icons/bs';


export default class Cont extends React.Component{
    
    constructor(){
        super();
        this.initState = {
            name: "",            
            email: "",
            phone: "",
            inquiry: "",
            count: 255
        }
        this.state = this.initState;
    }

    onChangeHendler = ev => {
        const {name, value} = ev.target;        
        this.setState({
            [name]: value
        });
    }

    onChangeCount = ev => {
        const {value} = ev.target;   
        let count = value.length; 
        if(count >= 0 && count < 256){            
            this.setState({
                inquiry: value,
                count: 255 - count
            });
        }
    }

    onChangeReset = () => {
        this.setState({
            
            inquiry: "",
            count: 255
        });
    }

    render(){
        return (
            <div>
            
                <Jumbotron  className="jumbotronTop" fluid>
                    <Container>
                        <h1 className="descShadow">Contact us</h1> 
                        <p>
                            It is our pleasure to help you in your business.
                        </p>
                    </Container>
                </Jumbotron>

                <Container>
                    <Row>
                        <Col>                           
                            <Jumbotron className="pt-4 pb-5 jumb">
                                <h4 className="colorH">A few ways</h4><hr />
                                phone: <a href="tel:07515457434">07515457434</a><hr />
                                e-mail: <a href="mailto:contact@skysoftp.co.uk">contact@skysoftp.co.uk</a><hr />
                                online: Inquiry form --&#8250;
                            </Jumbotron>    
                        </Col>                        
                        <Col>
                            <Jumbotron className="pt-4 pb-5 jumb">
                                <h4 className="colorH">Inquiry</h4><hr />
                                <Form>
                                <Form.Row>
                                    <Col>
                                        <InputGroup>
                                            
                                            <Form.Control as="textarea" rows="4" 
                                                onChange={this.onChangeCount}
                                                value={this.state.inquiry}                                                
                                                name="inquiry"                                         
                                                placeholder="Message"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <ButtonGroup> 
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                Reset inquiry
                                            </Tooltip>
                                        }
                                    > 
                                        <Button 
                                            variant={this.state.count===255?"warning":"success"}
                                            onClick={this.onChangeReset}
                                            overlay={
                                                <Tooltip>
                                                Tooltip on <strong>xxx</strong>.
                                                </Tooltip>
                                            }
                                        >
                                            <BsArrowCounterclockwise />
                                        </Button>
                                    </OverlayTrigger>
                                    <Button 
                                        className="maxWith"                  
                                        variant="secondary"                                        
                                        disabled="disabled"
                                    >
                                        You have left {this.state.count} chars              
                                    </Button>
                                </ButtonGroup>
                            </Form> 
                            </Jumbotron>                           
                        </Col>
                        <Col>
                            <Jumbotron className="pt-4 pb-5 jumb">
                                <h4 className="colorH">Contact detailes</h4><hr />
                                <Form>
                                <Form.Row>
                                    <Col>
                                        <InputGroup className="mb-1">
                                            <InputGroup.Prepend>
                                            <InputGroup.Text><BsPerson /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control 
                                                onChange={this.onChangeHendler}
                                                value={this.state.name}
                                                type="text"
                                                name="name"                                                
                                                placeholder="Name"                                               
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <InputGroup className="mb-1">
                                            <InputGroup.Prepend>
                                            <InputGroup.Text><GoMail /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control 
                                                onChange={this.onChangeHendler}
                                                value={this.state.email}
                                                type="email"
                                                name="email"                                                
                                                placeholder="Email"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>                                
                                <Form.Row>
                                    <Col>
                                        <InputGroup className="mb-1">
                                            <InputGroup.Prepend>
                                            <InputGroup.Text><HiOutlinePhoneIncoming /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control 
                                                onChange={this.onChangeHendler}
                                                value={this.state.phone}
                                                type="text"
                                                name="phone"                                                
                                                placeholder="Phone"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <SendData 
                                    data={this.state}
                                    valid={Object.values(this.state).some(x => x.length === 0)}
                                />                                
                            </Form>
                            </Jumbotron> 
                        </Col>                        
                    </Row>
                </Container>                 

            </div>
        );
    }
}