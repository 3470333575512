import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

export default function AllowCookies(){
    const [show, setShow] = useState(true);
    return (

        <Alert show={show} variant="success">
        <Alert.Heading>Privacy Policy!</Alert.Heading>
        <p>
        We use cookies to help give you the best experience on our site. <br />
        If you continue to use this site, we'll assume that you're happy to receive all cookies.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Accept and close
          </Button>
        </div>
      </Alert>

    );
}