import React from 'react';
import { FiSend } from "react-icons/fi";
import { ButtonGroup, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

export default class SendData extends React.Component{
    constructor(props){
        super();
        this.state = {
            isSending: 0 //0 wait for send, 1 sending, 2 finish send, 3 error, 4 wrong validation
        }
    }
      
    onSubmitHendler = ev => {
        ev.preventDefault(); 
        if(this.props.valid){
            this.setState({
                isSending: 4 //wrong validation
            });
        }else{
            this.setState({
                isSending: 1 //1 sending
            });
            this.fethConnect("php/phpMailer/src/send.php", JSON.stringify(this.props.data));
        }
    }

    fethConnect = (url='', data={}) => {
             
        try{
            fetch(url,{
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: data
            })
            .then(response => response.json())
            .then(json => this.setState({isSending: json})); //2 finish send
        }catch(error){
            this.setState({isSending: 3}); // error
        }
    }

    render(){
        return (
            <ButtonGroup>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip>
                            {this.state.isSending===2?"Thak you!":"Send message"}
                        </Tooltip>
                    }
                > 
                    <Button 
                        variant={this.props.valid?"warning":"success"}
                        onClick={this.onSubmitHendler}
                        disabled={(this.state.isSending===1)||(this.state.isSending===2)}
                    >
                        {this.state.isSending===1?<span className="spinner-border spinner-border-sm"></span>:<FiSend />}
                    </Button>
                </OverlayTrigger>
                <Button 
                    className="maxWith"             
                    variant="secondary"
                    disabled="disabled"
                >
                    {this.state.isSending === 0 ? 'Complete the form':
                    this.state.isSending === 1 ? 'Sending…':
                    this.state.isSending === 2 ? 'Your inquiry has been sent':
                    this.state.isSending === 3 ? 'Error, please try again later':'Fill in all fields'}                  
                </Button>
            </ButtonGroup>
        )
    }
}