import React from 'react';
import { Col, Row, Container, Jumbotron } from 'react-bootstrap'; 

export default function Home(){
    
    return (
        <div>
        
            <Jumbotron className="jumbotronTop" fluid>
                <Container>
                    <h1 className="descShadow">Welcome in our IT world</h1> 
                    <p>
                        We are proud of our applications
                    </p>
                </Container>
            </Jumbotron>

            <Container>
                <Row>
                    <Col>
                        <h6>We build the appilcations with passion.</h6>
                    </Col>
                    <Col>
                        <h6>We offer you build an app on commissioned.</h6>
                    </Col>
                    <Col>
                        <h6>Without themes. All the apps are unique.</h6>
                    </Col>                      
                </Row>
            </Container> 
            <hr />                

        </div>
    );
    
}