import React from 'react';
import { Col, Row, Container, Jumbotron } from 'react-bootstrap'; 

export default function Tech(){ 

    return (
        <div>
        
            <Jumbotron className="jumbotronTop" fluid>
                <Container>
                    <h1 className="descShadow">Technology which we use</h1> 
                    <p>
                    You can use many technologies but not all are the best.
                    </p>
                </Container>
            </Jumbotron>

            <Container>
                <Row>
                    <Col>
                        <Jumbotron className="pt-4 pb-5 jumb">
                            <h4 className="maxWith colorH">Database solution</h4><hr />
                            Databases: MySql<hr />
                            Usage: Stored Routines<hr />                          
                            Tools: phpMyAdmin
                        </Jumbotron>
                    </Col>
                    <Col>
                        <Jumbotron className="pt-4 pb-5 jumb">
                            <h4 className="maxWith colorH">Backend solution</h4><hr />                       
                            Language: PHP<hr />
                            Framework: Laravel  
                        </Jumbotron>                          
                    </Col>
                    <Col>
                        <Jumbotron className="pt-4 pb-5 jumb">
                            <h4 className="maxWith colorH">Frontend solution</h4><hr />
                            Language: HTML5, CSS, JavaScript<hr />
                            Library: React, Redux, Router, Hooks<hr />
                            Layout: Bootstrap 4.5   
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>                 

        </div>
    );
    
}